import React from 'react'
import {  FaFacebookF, FaLinkedinIn,FaTwitter,FaPinterestP } from "react-icons/fa";
import { Link } from 'react-router-dom';


export const Footer = () => {
  const footerulone =[{
      id: 1,
      heading: `IT Services`,
      des1: `Agile Transformation`,
      anchr1:`/agile`,

      des2: `Devops Methodology`, 
      anchr2:`/Devops-Methodology`,

      des3: `Application Development`, 
      anchr3:`/Application-Development`,

      des4:`Application Maintenance`,
      anchr4:`/Application-Maintenance`, 

      des5:`IOT Development`,
      anchr5:`/IOT-Development`,

      des6:`Software Design & Development`,
      anchr6:`/Software-Design-And-Development`,

      des7:`Startup Incubation`,
      anchr7:`/Startup-Incubation`,

      des8: `Startup It Support`,
     anchr8:`/start-up-it-support`,
  }
]
const footerultwo =[{
  id: 1,
  // heading: `Industries`,
  
  des1: `Smart Automation Strategies`, 
  anchr1:`/Smart-Automation-Strategies`,

  des2: `Managed Outsourcing Service`, 
  anchr2:`/Managed-Outsourcing-Service`,

  des3:`Influential Marketing`, 
  anchr3:`/Influential-Marketing`,

  des4:`Digital Marketing`,
  anchr4:`/Digital-Marketing`,

  des5:`BlockChain Development`,
  anchr5:`/blockchain-development`,

  des6:`Online Reputation Management`,
  anchr6:`/Online-Reputation-Managment`,

  des7:`Digital Consultation`,
  anchr7:`/Digital-Consultation`,

  des8:`Hire Developer`,
  anchr8:`/Hire-Developer`,

}
]
const footerulthree =[{
  id: 1,
  heading: `Company`,
  des1: `About`,
  anchr1:`/about`,
  des2: `Workday`,
  anchr2:`/workday`, 
  des3: `Industries`,
  anchr3:`/industries`,
  des4:`Contact`, 
  anchr4:`/Contact`,


}
]

 

  return (
    <section className="footer">
      <div className="container">
        <div className="flex spce-btwn">
          <div className="box ft-box">
           <div className="footer-logo"> 
           <Link to="/"><img src="./assets/img/logo-tech.png" alt="" className="img-fluid"/></Link>

           </div>
             <p>
             Techsaga Corporation is integrated into the muse of a multifaceted group inherited with a tested tune of experience and eminent talent. 
             </p>
            <ul>
              <li className="address"><span>2728 Steece way Leander, TX 78641 United States</span></li>
              <li className="phone"><a href="tel:+1(856) 888-9026"> <span>+1(856) 888-9026</span></a></li>
              <li className="mail"><a href="mailto:info@techsaga.us"><span>info@techsaga.us</span></a></li>
            </ul>
          </div>
          {
            footerulone.map((footEL)=>{
              return(
                <div className="ft-box" key={footEL.id}>
                <div className="float-right">
                <h4>{footEL.heading}</h4>
            <ul>
              <li><Link to={footEL.anchr1}>{footEL.des1}</Link></li>
              <li><Link to={footEL.anchr2}>{footEL.des2}</Link></li>
              <li><Link to={footEL.anchr3}>{footEL.des3}</Link></li>
              <li><Link to={footEL.anchr4}>{footEL.des4}</Link></li>
              <li><Link to={footEL.anchr5}>{footEL.des5}</Link></li>
              <li><Link to={footEL.anchr6}>{footEL.des6}</Link></li>
              <li><Link to={footEL.anchr7}>{footEL.des7}</Link></li>
              <li><Link to={footEL.anchr8}>{footEL.des8}</Link></li>
            </ul>
                </div>
          </div>
              )
            })
          }
          {
            footerultwo.map((footEL)=>{
              return(
                <div className="ft-box" key={footEL.id}>
                <div className="float-right">
                <h4>{footEL.heading}</h4>
            <ul class="second-block">
              
              <li><Link to={footEL.anchr1}>{footEL.des1}</Link></li>
              <li><Link to={footEL.anchr2}>{footEL.des2}</Link></li>
              <li><Link to={footEL.anchr3}>{footEL.des3}</Link></li>
              <li><Link to={footEL.anchr4}>{footEL.des4}</Link></li>
              <li><Link to={footEL.anchr5}>{footEL.des5}</Link></li>
              <li><Link to={footEL.anchr6}>{footEL.des6}</Link></li>
              <li><Link to={footEL.anchr7}>{footEL.des7}</Link></li>
              <li><Link to={footEL.anchr8}>{footEL.des8}</Link></li>
          
            </ul>
                </div>
          </div>
              )
            })
          }
         
          {
            footerulthree.map((footEL)=>{
              return(
                <div className="ft-box" key={footEL.id}>
                <div className="float-right">
                <h4>{footEL.heading}</h4>
            <ul>
              <li><Link to={footEL.anchr1}>{footEL.des1}</Link></li>
              <li><Link to={footEL.anchr2}>{footEL.des2}</Link></li>
              <li><Link to={footEL.anchr3}>{footEL.des3}</Link></li>
              <li><Link to={footEL.anchr4}>{footEL.des4}</Link></li>
            
            </ul>
                </div>
          </div>
              )
            })
          }
         
         
        </div>
         <div className="copy-right">
             <div className="flex spce-btwn">
                  <div className="left">
                    <p>© Copyright 2022. All Rights Reserved.</p>
                  </div>   
                  <div className="right">
                  <div className="social-box">
                 <a href="https://www.facebook.com/techsaga.us" target="_blank" rel="noopener noreferrer"><FaFacebookF /></a>
                 <a href="https://www.linkedin.com/company/techsaga_us/" target="_blank" rel="noopener noreferrer"><FaLinkedinIn /></a>
                 <a href="https://twitter.com/techsaga_us" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
                 <a href="https://in.pinterest.com/techsaga_us/" target="_blank" rel="noopener noreferrer"><FaPinterestP/></a>
               
                </div>
                  </div>
             </div>
         </div>
      </div>

    </section>
  )
}

export default Footer;