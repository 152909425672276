// import React from 'react';
import React, { useState, useEffect } from 'react';
import { FaSearch, FaRegClock, FaRegCalendarAlt } from "react-icons/fa";
// import blogs from './BlogCompo';
import { Link } from 'react-router-dom';
export const MainBlog = () => {

  const [blogs, setBlogData] = useState([]);

  useEffect(() => {
    // Fetch data from your API endpoint
    fetch('https://resources.techsaga.co.in/wp-json/wp/v2/post/?cat_id=1')
      .then((response) => response.json())
      .then((data) => setBlogData(data.data))
      .catch((error) => console.error('Error fetching blog data:', error));

      // console.log(blogs);
  }, []);

  const renderHtmlContent = (htmlString, maxLength) => {
    // // Truncate the HTML string to a specific length
    // const truncatedHtml = htmlString.length > maxLength ? `${htmlString.slice(0, maxLength)}...` : htmlString;
    // return { __html: truncatedHtml };
  };
// console.log("blogs",blogs);
  return (
    <section className="mainblog pt-top pt-btm">
      <div className="container">
        <div className="flex spce-btwn">
          <div className="">
            <div className="flex">
              {blogs.map((blogEl) => {
                return (
                  
                    <div className="blog-wrap mb-35" key={blogEl.post_id}>
                      <a href={blogEl.post_link}>
                        <div className="img-part">
                          <img src={blogEl.link} alt="" className="img-fluid" />
                        </div>
                        <div className="content-part">
                          {/* <Link className="categories" to="">
                            {blogEl.mintitle}
                          </Link> */}
                          <h3 className="title pb-2">
                            {/* <a href={blogEl.post_link} target='_blank'>
                              {blogEl.post_title}
                            </a> */}
                            {blogEl.post_title}
                          </h3>
                          <div className="blog-meta">
                            {/* <div className="user-data">
                            <p dangerouslySetInnerHTML={renderHtmlContent(blogEl.post_content,140)} />
                            </div> */}
                            <div className="date">
                              <FaRegClock /><span>{blogEl.post_date}</span>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                )
              })
              }
            </div>
          </div>
          
          {/* <div className="right">
            <div className="search">
              <input type="search" placeholder="search" />
              <span className="fasearch"> <FaSearch /> </span>
            </div>
            <div className="latest-posts">
              <h4><span>Latest</span> Posts</h4>
              {
                blogs.map((BlogMainEl, Index) => {
                  if (Index < 5) {
                    return (
                      <div className="box" key={BlogMainEl.id}>
                        <div className="flex spce-btwn">
                          <div className="img">
                            <img src={BlogMainEl.img} alt="" />
                          </div>
                          <div className="contant">
                            <h5>{BlogMainEl.heading}</h5>
                            <div>
                              <span> <FaRegCalendarAlt /></span>
                              <span>{BlogMainEl.date}</span>
                            </div>

                          </div>
                        </div>
                      </div>
                    )
                  }
                  else{}

                })
              }


            </div>
            <div className="categories">
              <h4>Categories</h4>
              <ul>
                <li>Application Testing</li>
                <li>Artifical Intelligence</li>
                <li>Digital Technology</li>
                <li>IT Services</li>
                <li>Software Development</li>
                <li>Web Development</li>
              </ul>

            </div>




          </div> */}
        </div>
        <div className="viewmorebtn">
          <a href='https://resources.techsaga.co.in/category/usa/' className="glb-button blogview">View More</a>
          </div>
      </div>
    </section>
  )
}
