import React,{useEffect} from 'react'
import { FaLongArrowAltRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export const ServiceSidebar = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
   const servicesidebar=[
    {
      id:1,
      heading:`Integrated Brand Management`,
      anchr:`/Integrated-Brand-Management`,
    },
    {
      id:2,
      heading:`Online Reputation Management`,
      anchr:`/Online-Reputation-Managment`,
    },
    {
      id:3,
      heading:`Managed Outsourcing Service`,
      anchr:`/Managed-Outsourcing-Service`,
    },
    {
      id:4,
      heading:`Software Design And Development`,
      anchr:`/Software-Design-And-Development`,
    },
    {
      id:5,
      heading:`Smart Automation Strategies`,
      anchr:`/Smart-Automation-Strategies`,
    },
   {
    id:6,
    heading:`Devops Methodology`,
    anchr:`/Devops-Methodology`,
  },
  {
    id:7,
    heading:`Application Development`,
    anchr:`/Application-Development`,
  },
  {
    id:8,
    heading:`Application Maintenance`,
    anchr:`/Application-Maintenance`,
  },
  {
    id:9,
    heading:`IOT Development`,
    anchr:`/IOT-Development`,
  },
  {
    id:10,
    heading:`Startup IT Support`,
    anchr:`/start-up-it-support`,
  },
  {
    id:11,
    heading:`Agile Transformation`,
    anchr:`/agile`,
  },
 
  {
    id:12,
    heading:`Influential Marketing`,
    anchr:`/Influential-Marketing`,
  },
  {
    id:13,
    heading:`Digital Marketing`,
    anchr:`/Digital-Marketing`,
  },
  {
    id:14,
    heading:`Blockchain Development`,
    anchr:`/blockchain-development`,
  },
  {
    id:15,
    heading:`Startup Incubation`,
    anchr:`/Startup-Incubation`,
  },
  {
    id:16,
    heading:`Digital Consultation`,
    anchr:`/Digital-Consultation`,
  },
 
  {
    id:17,
    heading:`Hire Developer`,
    anchr:`/Hire-Developer`,
  },
  
  ]
  return (
    <section className="servicesidebar">
          {
            servicesidebar.map((relEl)=>{
                return(
                     <div className="sidebar-box flex align-center spce-btwn" key={relEl.id}>
                      <Link to={relEl.anchr} className="sideservices1">
                      <h4>{relEl.heading}</h4>
                         <span className='right-arw'>&raquo;</span>
                      </Link>
                     {/* <FaLongArrowAltRight/> */}
                    
                    </div>
                )
            })
          }
    </section>
  )
}
