import React,{useEffect} from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from 'react-router-dom';
export const RelatedService = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const relatedservice = [
    {
      id: 1,
      bckimg:`./assets/img/bg17.jpg`,
      img:`./assets/icon/technology.png`,
      heading:`Block Chain Development`,
      anchr:`/blockchain-development`,
      para:`Blockchain is flourishing, inevitably over the years. Unarguably, blockchain rules the digital world across all verticals..`,
    },
    {
      id: 2,
      bckimg:`./assets/img/agile12.png`,
      img:`./assets/icon/technology.png`,
      heading:`Agile Transformation`,
      anchr:`/agile`,
      para:`Techsaga entails the power of agile transformation, driving an organization's success.`,
    },
    {
      id: 3,
      bckimg:`./assets/img/devops.png`,
      img:`./assets/icon/technology.png`,
      heading:`Devops Methodology`,
      anchr:`/Devops-Methodology`,
      para:`Techsaga is a leading DevOps consulting service provider for modern software development.`,
    },
    {
      id: 4,
      bckimg:`./assets/img/application_serivices.jpg`,
      img:`./assets/icon/technology.png`,
      heading:`Application Development`,
      anchr:`/Application-Development`,
      para:`Accelerate your business growth with our next-gen app development practices, offering simplifies business complexities..`,
    },
    {
      id: 5,
      bckimg:`./assets/img/IOT_services1.jpg`,
      img:`./assets/icon/technology.png`,
      heading:`IOT Development`,
      anchr:`/IOT-Development`,
      para:`Build your IOT solutions with our full potential in IoT development services for any industry and vertical.`,
    },
    {
      id: 6,
      bckimg:`./assets/img/strtup-side-banner-services.png`,
      img:`./assets/icon/technology.png`,
      heading:`Startup IT Support`,
      anchr:`/start-up-it-support`,
      para:`With a proven track record and 12+ years of experience, Techsaga providing assistance to budding companies to identify`,
    },
    {
      id: 7,
      bckimg:`./assets/img/Smart-Automation.jpg`,
      img:`./assets/icon/technology.png`,
      heading:`Smart Automation Strategies`,
      anchr:`/Smart-Automation-Strategies`,
      para:`Unleash automation potential with Techsaga to drive your business forward. Techsaga with 12+ years of expertise`,
    },
  
    {
      id: 8,
      bckimg:`./assets/img/Manage-outsourcing1.jpeg`,
      img:`./assets/icon/technology.png`,
      heading:`Managed Outsourcing Service`,
      anchr:`/Managed-Outsourcing-Service`,
      para:`Techsaga team helps you to achieve your product development goals. Our engineers become an integral part of your development cycle`,
    },
    {
      id: 9,
      bckimg:`./assets/img/influencer-marketing-platforms.webp`,
      img:`./assets/icon/technology.png`,
      heading:`Influential Marketing`,
      anchr:`/Influential-Marketing`,
      para:`Influence Marketing connecting brands to social media influencers to tell great stories. `,
    },
    {
      id: 10,
      bckimg:`./assets/img/digital-marketingpart.webp`,
      img:`./assets/icon/technology.png`,
      heading:`Digital Marketing`,
      anchr:`/Digital-Marketing`,
      para:`Techsaga offers full suite of digital marketing services with exception marketing skills to drive new customers.. `,
    },
    {
      id: 11,
      bckimg:`./assets/img/startup-icubebation-services.jpg`,
      img:`./assets/icon/technology.png`,
      heading:`Startup Incubation`,
      anchr:`/Startup-Incubation`,
      para:`Get end-to-end Incubation services from Techsaga for your business. With our Startup Incubation services.. `,
    },
    {
      id: 12,
      bckimg:`./assets/img/sd&d.webp`,
      img:`./assets/icon/technology.png`,
      heading:`Software Design And Development`,
      anchr:`/Software-Design-And-Development`,
      para:`Techsaga is a top Indian software development company that builds customized software as per business needs. `,
    },
    {
      id: 13,
      bckimg:`./assets/img/Online-reputation-management_Curvearro.jpeg`,
      img:`./assets/icon/technology.png`,
      heading:`Online Reputation Management`,
      anchr:`/Online-Reputation-Managment`,
      para:`We provide customized ORM services depending upon your unique needs and requirements. Our only goal is to increase your overall positive web presence. `,
    },
    {
      id: 14,
      bckimg:`./assets/img/hire-developers1.jpg`,
      img:`./assets/icon/technology.png`,
      heading:`Hire Developer`,
      anchr:`/Hire-Developer`,
      para:`Leverage Techsaga’s process of building and managing dedicated team of developers. `,
    },
    {
      id: 15,
      bckimg:`./assets/img/integrated-brand-managementpart.jpg`,
      img:`./assets/icon/technology.png`,
      heading:`Integrated Brand Management`,
      anchr:`/Integrated-Brand-Management`,
      para:`At Techsaga, we help companies, brands and businesses by providing a sound marketing strategy based on information and research.  `,
    },
    {
      id: 16,
      bckimg:`./assets/img/digital-consulting-uai.jpg`,
      img:`./assets/icon/technology.png`,
      heading:`Digital Consultation`,
      anchr:`/Digital-Consultation`,
      para:`Techsaga’s consulting services offers strategy to execution, leveraging modern interfaces and technologies to improve your digital customer experience.  `,
    },
    {
      id: 17,
      bckimg:`./assets/img/Application-Maintenance-Services.jpg`,
      img:`./assets/icon/technology.png`,
      heading:`Application Maintenance`,
      anchr:`/Application-Maintenance`,
      para:`Our App Maintenance developeras offers full suite of Application Support services.Techsaga connect to business..   `,
    },
  ];
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className='related-service'>
           <h3>Related Service</h3>
           {/* <div className="flex spce-btwn"> */}
           <Carousel
          responsive={responsive}
          infinite={true}
          autoPlaySpeed={3000}
          arrows={true}
          autoPlay={true}
        >
               {
                relatedservice.map((relEl)=>{
                     return(
                       <div className='related-box' style={{ backgroundImage: `url("${relEl.bckimg}")` }} key={relEl.id}>
                            <div className="box">
                               <img src={relEl.img} alt="" />
                               <Link to={relEl.anchr}><h4>{relEl.heading}</h4></Link>
                               <p>{relEl.para}</p>
                                <Link to={relEl.anchr} className="glb-button">Read More</Link>
                            </div>
                       </div>
                     )
                })
               }
                  </Carousel>
           {/* </div> */}
    </section>
  )
}

