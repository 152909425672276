import React from 'react'
import { Link } from 'react-router-dom';
const Services = (prop) => {
    const service =[
        {
            id:1,
            img:`./assets/img/agile.webp`,
            title:`Agile Transformation`,
            anchr:`/agile`,
            iconsec:`./assets/icon/004-data.svg`,
            description:`Techsaga entails the power of agile transformation, driving an organization's success...`,
        },
        {
            id:2,
            img:`./assets/img/devops.webp`,
            title:`Devops Methodology`,
            anchr:`/Devops-Methodology`,
            iconsec:`./assets/icon/001-smartphone.svg`,
            description:`Techsaga is a leading DevOps consulting service provider for modern software development...`,
        },
        {
            id:3,
            img:`./assets/img/application-development.webp`,
            title:`Application Development`,
            anchr:`/Application-Development`,
            iconsec:`./assets/icon/001service.png`,
            description:`Accelerate your business growth with our next-gen app development practices, offering simplifies business...`,
        },
        {
            id:4,
            img:`./assets/img/application-maintaince.webp`,
            title:`Application Maintenance`,
            anchr:`/Application-Maintenance`,
            iconsec:`./assets/icon/002service.png`,
            description:`Our App Maintenance developers offers full suite of Application Support services...`,
        },
        {
            id:5,
            img:`./assets/img/iot-development.webp`,
            title:`IoT Development`,
            anchr:`/IOT-Development`,
            iconsec:`./assets/icon/003service.png`,
            description:`Build your IOT solutions with our full potential in IoT development services for any industry and vertical...`,
        },
        // {
        //     id:6,
        //     img:`./assets/img/software-design.webp`,
        //     title:`Reduce Infrastructure Cost`,
        //     iconsec:`./assets/icon/006service.png`,
        //     description:`Handling the expenses over IT structure and characterizing business with providential cost-optimization...`,
        // },
        {
            id:6,
            img:`./assets/img/software-design.webp`,
            title:`Software Design and Development`,
            anchr:`/Software-Design-And-Development`,
            iconsec:`./assets/icon/008service.png`,
            description:`Techsaga is a top Indian software development company that builds customized software as per business needs...`,
        },
        {
            id:7,
            img:`./assets/img/startup-incubation.webp`,
            title:`Startup Incubation`,
            anchr:`/Startup-Incubation`,
            iconsec:`./assets/icon/005service.png`,
            description:`Get end-to-end Incubation services from Techsaga for your business. With our Startup Incubation services...`,
        },
        {
            id:8,
            img:`./assets/img/startup-it-support.webp`,
            title:`Startup IT Support`,
            anchr:`/start-up-it-support`,
            iconsec:`./assets/icon/009service.png`,
            description:`With a proven track record and 12+ years of experience, Techsaga providing assistance to budding companies to identify...`,
        },
        {
            id:9,
            img:`./assets/img/block-chain-development.webp`,
            title:`Blockchain Development`,
            anchr:`/blockchain-development`,
            iconsec:`./assets/icon/004service.png`,
            description:`In recent years, the rapid transformation of the technology world has led blockchain to attain new milestones and has emerged as the most promising technological alternative for most businesses worldwide...`,
        },
    
        {
            id:10,
            img:`./assets/img/outsourcing.webp`,
            title:`Managed Outsourcing`,
            anchr:`/Managed-Outsourcing-Service`,
            iconsec:`./assets/icon/010service.png`,
            description:`Techsaga team helps you to achieve your product development goals. Our engineers become an integral part of your development cycle...`,
        },
        {
            id:11,
            img:`./assets/img/brand-management.webp`,
            title:`Integrated Brand Management`,
            anchr:`/Integrated-Brand-Management`,
            iconsec:`./assets/icon/012service.png`,
            description:`At Techsaga, we help companies, brands and businesses by providing a sound marketing strategy based on information and research...`,
        },
        {
            id:12,
            img:`./assets/img/digital-marketing.webp`,
            title:`Digital Marketing`,
            anchr:`/Digital-Marketing`,
            iconsec:`./assets/icon/015service.png`,
            description:`Techsaga offers full suite of digital marketing services with exception marketing skills to drive new customers to grow your business...`,
        },
        {
            id:13,
            img:`./assets/img/reputation-management.webp`,
            title:`Online Reputation Management`,
            iconsec:`./assets/icon/011service.png`,
            anchr:`/Online-Reputation-Managment`,
            description:`We provide customized ORM services depending upon your unique needs and requirements. Our only goal is to increase your overall positive web presence...`,
        },
        {
            id:14,
            img:`./assets/img/infulance-marketing.webp`,
            title:`Influential Marketing`,
            anchr:`/Influential-Marketing`,
            iconsec:`./assets/icon/012service.png`,
            description:` Influence Marketing connecting brands to social media influencers to tell great stories...`,
        },
        {
            id:15,
            img:`./assets/img/cusulting.webp`,
            title:`Digital Consultation`,
            anchr:`/Digital-Consultation`,
            iconsec:`./assets/icon/013service.png`,
            description:`Techsaga’s consulting services offers strategy to execution, leveraging modern interfaces and technologies to improve your digital customer experience... `,
        },
        // {
        //     id:16,
        //     img:`./assets/img/improved-roi.webp`,
        //     title:`Improved ROI`,
        //     iconsec:`./assets/icon/016service.png`,
        //     description:` Demonstrating the techniques to enhance the Return on Investment of your commercial enterprise creating a providential profit...`,
        // },
        {
            id:16,
            img:`./assets/img/hire-developer.webp`,
            title:`Hire Developer`,
            anchr:`/Hire-Developer`,
            iconsec:`./assets/icon/sales-ic.png`,
            description:`Leverage Techsaga’s process of building and managing dedicated team of developers...`,
        },
        
        
    ];
    return (
        <section className="service pt-top pt-btm">
        <div className="container">
            <span className="title">PREMIUM SERVICES</span>
            <h2>Our Latest Services</h2>
            <div className="flex spce-btwn">
                 {service.map((serEl, index) => {
                     if(index < prop.activeCard){
                        return(
                        <div className="box" key={serEl.id}>
                        <div className="img">
                            <img src={serEl.img} alt="service" className="img-fluid" />
                             <div className="icon-sec">
                             <img src={serEl.iconsec} alt="service" />
                             </div>
                        </div>
                        <div className="box-content">
                        <Link to={serEl.anchr}><h3>{serEl.title}</h3></Link>
                        <p>{serEl.description}</p>
                        </div>
                    
                        <div className="outer-readmore">
                           <div className="inner-readmore">
                           <div className="read-more">
                               <Link to={serEl.anchr}>Read More</Link>
                           </div>
                           </div>
                        </div>
                       </div>
                    )
                     }
                     else{
                        return
                 }
                        
                 } )

                   
                 }
            </div>
        </div>
    </section>
    )
}

export default Services;

